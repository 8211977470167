import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../components/ChineseLayout"
import Accordion from "../../components/Accordion"
import AtTheAirport from "../../images/static-only/flying-tips/At_the_airport.jpg"
import Comfort from "../../images/static-only/flying-tips/comfort-2.jpg"
import FlightTips from "../../images/static-only/flying-tips/Flight_tips.jpg"
import KeepingThemEntertained from "../../images/static-only/flying-tips/Keeping_them_entertained.jpg"
import PreparingToTravel from "../../images/static-only/flying-tips/Preparing_to_travel.jpg"
import PreventingPain from "../../images/static-only/flying-tips/Preventing_ear_pain_and_relief.jpg"
// import Inactivity from "../../components/Inactivity"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 飞行提示</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={FlightTips} alt="An airplane cabin" />

            <div className="hero-area__inner">
              <h1>飞行提示</h1>
              <nav className="breadcrumb" role="navigation">
                <Link to="/zh" title="首页">
                  <i className="fas fa-arrow-left"></i>
                  首页
                </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">一、出行准备</div>
              <div className="accordion__content">
                <img src={PreparingToTravel} alt="A mother and child packing" />
                <div className="rich-text">
                  <p>       在您出行之前，让您的孩子知道即将到来的事情，这可以帮助减轻到达机场时可能造成的任何潜在影响。 这也有助于确保旅行不会成为令宝宝害怕的体验，而是令宝宝感到期待和兴奋的事情。</p>

                  <h3>记得写一份出行清单</h3>
                  <p>       在打包随身行李之前，写一份出行清单是个不错的主意。 请注意，机场零售商很少会携带足够的婴儿用品，因此提前几天确认您的所需，可以帮助您确保旅行顺利。</p>
                  <p>以下是必需品的建议清单：</p>
                  <ul>
                    <li>为您和您的宝宝至少准备更换一件衣服。 如果您将长途飞行，可能需要准备更多件</li>
                    <li>携带小毯子 - 它们有助于在护理期间保护您的隐私，和保证宝宝的温暖，如果宝宝在喂奶后出现吐奶等情况，也会帮助到您</li>
                    <li>尿布和婴儿湿巾。 保证旅行期间中有足够的备用，建议准备额外24小时可用的用品，以防面对飞机延误等意外情况</li>
                    <li>如果您正在哺乳期，护理垫和护罩可以在发生意外时提供帮助</li>
                    <li>保鲜袋和密实袋可用于存放零食，并将脏衣服分开放在婴儿袋中</li>
                    <li>如果您将进行人工喂养，请携带配方奶和奶瓶。 再次提醒，如果出现延误，请备用在计划旅行时间额外24小时的足量用品</li>
                    <li>确保所有电子设备都已充满电，并下载了影片和游戏</li>
                    <li>书籍和几个宝宝最喜欢的玩具</li>
                    <li>婴儿吊带或背带</li>
                    <li>婴儿食品</li>
                  </ul>

                  <h3>药物</h3>
                  <p>       请记得在婴儿背袋中携带您可能需要的任何处方和其他药物，以方便使用。 检查有关药物和液体药物的规定，有无处方。 为了宝宝的安全考虑，请将勿拆除药物原始包装，以便于识别。</p>
                  
                  <h3>舒适的穿着</h3>
                  <p>       旅行时需要舒适和便利，让那些可爱繁琐的服装乖乖在家等待吧！ 选择舒适且易于更换的服装。在不同气候的地区之间旅行时，您可能需要一件小外套，这也有助于适应飞机上的温度改变。 如果你在飞机跑道上延误等待，往往会变得太热，一旦飞机升空，因温度下降，你可能需要额外的毯子。</p>

                  <h3>-免责声明</h3>
                  <p>该消息仅供参考。 育儿建议仅作为建议。 该信息不会取代医生的任何建议。 该信息不适用于任何司法管辖区，也不应用于替代医疗，护理或专业医疗保健建议或服务或医学实践。</p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">二、机场</div>
              <div className="accordion__content">
                <img src={AtTheAirport} alt="A mother and child at an airport lounge" />
                <div className="rich-text">
                  <h3>安全检查</h3>
                  <p>       为了避免因安全检查导致延误，如果您是配方奶人工喂养的话，最好先查看机场指南，了解携带粉末的安全性。 您还可以购买分装配方包。 这样，您只需要在安全检查过后加水，如果机上空乘人员愿意提供瓶装水，可以将其放进热水中加热。</p>

                  <h3>在起飞前去洗手间</h3>
                  <p>       宝宝需要穿着干净且干燥的尿布搭乘飞机，所以一定要在登机前最后一次去机场洗手间。 您可能需要让宝宝穿着两个尿布，以提供额外的防漏保护。如果您的孩子在进行使用便盆的训练——当然您也会想要这样做的。 飞机前的卫生间使用意味着您可以无需多次使用窄小的飞机洗手间。 这样可以减少您在飞机上的活动，以免给坐在您周围的人带来过多的不便。</p>

                  <h3>婴儿用品检查</h3>
                  <p>       登机安顿下来之后，您需要更多的时间陪伴宝宝。 您可能认为在飞机上花费尽可能少的时间陪同宝宝是更优的解决方案，但是，与宝宝一起飞行通常会有各方面的压力，预先登机还可以避免其他乘客的叹气和不耐烦。</p>

                  <h3>-免责声明</h3>
                  <p>该消息仅供参考。 育儿建议仅作为建议。 该信息不会取代医生的任何建议。 该信息不适用于任何司法管辖区，也不应用于替代医疗，护理或专业医疗保健建议或服务或医学实践。</p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">三、机上母乳喂养</div>
              <div className="accordion__content">
                <img src={FlightTips} alt="An airplane cabin" />
                <div className="rich-text">
                  <p>      在飞机上很可能需要进行必要的母乳喂养，但如果你对喂养感到紧张，你可以将母乳存取并将它带到飞机上——机场安检将只用手持设备扫描母乳。如果您对哺乳时身旁有陌生人感到不舒服，请携带两个安全别针。 这样，您就可以将收纳毯或机上毯子固定在您前面的座位和您自己座位的侧面，以形成一个隐私小窗帘。</p>

                  <h3>-免责声明</h3>
                  <p>该消息仅供参考。 育儿建议仅作为建议。 该信息不会取代医生的任何建议。 该信息不适用于任何司法管辖区，也不应用于替代医疗，护理或专业医疗保健建议或服务或医学实践。</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">四、预防和缓解耳痛</div>
              <div className="accordion__content">
                <img src={PreventingPain} alt="A toddler looking out a plane window" />
                <div className="rich-text">
                  <p>如果您有任何疑虑，建议您在旅行开始之前与您的儿科医生或医疗保健人员联系，以预防或缓解常见的飞机问题，如耳痛。</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">五、机上舒适性和安全性</div>
              <div className="accordion__content">
                <img src={Comfort} alt="A baby sleeping on their mother's chest" />
                <div className="rich-text">
                  <h3>座位选择</h3>
                  <p>       如果您的孩子未满两岁，且您选择让他们在飞行期间保持坐在您的膝盖上，则无需购买单独的座位。您也可以为您的宝宝申请摇篮座位，但规则和可用性因航空公司而异，因为摇篮座位很受欢迎。如果您想购买座位，您的孩子必须使用FAA（联邦航空管理局）汽车安全座椅。从两岁开始，您的孩子必须拥有自己的座位。建议您咨询航空公司，了解有关携带婴儿和购买座位的旅行指南。扣在汽车座椅或其他认可的约束装置上是婴儿或幼儿在飞机上最安全的地方，尤其是在出现意外湍流的情况下。有时您可能需要解开安全带为孩子换尿布，使用便盆或运动，这在长途飞行中尤其重要。一旦你的孩子解开安全带，你可能很难让他们重新回到座位上，如果他们是一个刚走路的小孩，他们可以坐在你的膝盖上，也乐意在过道上走来走去。因此，最好在合理的时间内保持宝宝在座椅上安全固定。</p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">六、让宝宝娱乐</div>
              <div className="accordion__content">
                <img src={KeepingThemEntertained} alt="A pot of colouring pencils" />
                <div className="rich-text">
                  <p>       与婴儿或幼儿一起飞行可能会有压力，但您可以采取一些措施来保持您的小孩娱乐，以确保旅程中尽可能愉快。携带或购买合适的玩具，大量的食物和零食，并确保您有一些电影和应用程序下载到您的电子设备，只是让您的孩子在飞机上保持舒适和娱乐的一些方法。重要的是要记住，由于耳道较小，儿童耳朵听到的声音会显得更响。如果您使用耳机，请确保您的孩子能够告诉您音量是否足够舒适。可能会有不耐烦的乘客，无论你采取什么措施让婴儿或幼儿努力安顿下来时，他们在飞机上仍可能发脾气。这将不可避免地导致飞行中断，一些乘客变得明显受到影响。重要的是，您应尽力尝试抚慰您的孩子，并被其他旅行者所见。说起来容易做起来难，但请注重照顾宝宝，忽视那些无礼粗鲁的人。您的飞机将很快安全降落，希望您能够继续享受下一段快乐的旅程。</p>
                  <p>在机上抚慰孩子的小贴士：</p>
                  <ul>
                    <li>申请过道座位。一个额外的空间可以产生很大的不同，减轻您和您的小孩的不适，这比与坐在两个人之间更好。</li>
                    <li>在过道上来回走动可能会有所帮助，如果宝宝好动，他们也不会给其他乘客带来太多不便。</li>
                    <li>阅读故事或玩游戏。你可能会觉得有点打扰到他人，但是对于其他乘客而言，快乐的婴儿比不快乐和烦躁的婴儿更令人可以接受。</li>
                    <li>在起飞和降落时喂养宝宝有助于防止因吞咽而导致的耳朵不舒服。</li>
                  </ul>

                  <h3>-免责声明</h3>
                  <p>该消息仅供参考。 育儿建议仅作为建议。 该信息不会取代医生的任何建议。 该信息不适用于任何司法管辖区，也不应用于替代医疗，护理或专业医疗保健建议或服务或医学实践。</p>

                </div>
              </div>
            </div>

          </section>

        </main>
      </ChineseLayout>
    )
  }
}

export default AccordionListing