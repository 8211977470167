function Accordion() {
  /* Assign variables */
  var accordionHeading = document.querySelectorAll('.accordion__heading')
  var heroArea = document.querySelector('.hero-area')
  var breadcrumbAccordion = document.querySelector('.breadcrumb.-accordion')
  var contentMain = document.querySelector('.-content-accordion-listing')
  var siteHeader = document.querySelector('.site-header')

  /* Loop through all accordionHeadings and bind a click event listener */
  for (var i = 0; i < accordionHeading.length; i++) {
    accordionHeading[i].addEventListener('click', function(e) {
      /* Assign target and parent variables */
      var el = e.target
      var accordion = el.parentNode

      /* If statement to determine whether to hide or show the clicked Accordion's corresponding content */
      
      if (accordion.classList.contains('-active')) {

        accordion.classList.remove('-active')
        accordion.querySelector('.accordion__content').classList.remove('-active')

        heroArea.classList.remove('-accordion-active')
        breadcrumbAccordion.classList.remove('-accordion-active')
        siteHeader.classList.remove('-accordion-active')

      } else {

        accordion.classList.toggle('-active')
        accordion.querySelector('.accordion__content').classList.toggle('-active')

        heroArea.classList.add('-accordion-active')
        breadcrumbAccordion.classList.add('-accordion-active')
        contentMain.classList.add('-accordion-active')
        siteHeader.classList.add('-accordion-active')

      }  
    })
  }
}

export default Accordion